<template>
  <a-layout class="layout">
    <!-- <a-layout-header class="header">

     </a-layout-header> -->
    <a-layout-sider
      v-model="collapsed"
      :trigger="null"
      collapsible
      class="app-sider"
    >
      <div class="logo">
        <a-icon style="font-size: 24px; color: #2d88ff" type="slack" />
        <h1 class="h1Class">智慧水务管理系统</h1>
      </div>
      <SideMenu :openCurrent="openCurrent" @clearPath="clearPath"></SideMenu>
    </a-layout-sider>
    <a-layout :style="'width:' + (fullWidth - 200) + 'px'">
      <a-layout-header class="app-header" v-if="!showScreen">
        <span class="app-header-trigger">
          <a-icon
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => (collapsed = !collapsed)"
          />
        </span>
        <!-- <span class="app-header-refresh">
          <a-icon type="reload"></a-icon>
        </span> -->
        <audio id="audio" preload="auto" loop>
          <source src="../assets/audio/14039.wav" type="audio/wav" />
        </audio>
        <div class="app-header-right">
          <a-menu mode="horizontal" @click="handleClick">
            <a-menu-item>
              <a-badge :count="total.total" :overflow-count="99">
                <a-icon type="bell" @click="lookAll" />
              </a-badge>
            </a-menu-item>
            <a-sub-menu>
              <span class="app-user-avatar" slot="title">
                <a-avatar size="small" :src="headerImageUrl" />
                {{ User ? User.username : "" }}
              </span>
              <a-menu-item key="/setting/profile" v-if="showModel[1]"
                ><a-icon type="user" key="user" />个人资料
              </a-menu-item>
              <a-menu-item key="/setting/password" v-if="showModel[2]"
                ><a-icon type="lock" key="password" />修改密码
              </a-menu-item>
              <a-menu-item key="/login"
                ><a-icon type="poweroff" />退出登录
              </a-menu-item>
            </a-sub-menu>
            <!-- <a-menu-item key="/help">
              <a-icon type="question-circle"
            /></a-menu-item> -->
          </a-menu>
        </div>
      </a-layout-header>
      <a-layout-content>
        <router-view />
      </a-layout-content>
    </a-layout>
    <a-modal
      :visible="errorReadOrNoreadFlag"
      :closable="false"
      :maskClosable="true"
      footer
      width="800px"
      class="modal"
      @cancel="handleCancel"
    >
      <div class="modalDiv">
        <a-row>
          <a-col :span="8" class="left">
            <p class="title">消息通知</p>
            <div class="imgDiv">
              <div class="leftImg">
                <img src="@/assets/images/home/deviceError.png" alt="" />
              </div>
              <div class="rightDetail">
                <p class="title">设备预警</p>
                <p class="mark">通知设备在线、离线等情况</p>
              </div>
            </div>
          </a-col>
          <a-col :span="16" class="right">
            <a-tabs default-active-key="0" @change="systemNoticeTabchange">
              <a-tab-pane key="0" tab="未读">
                <div class="statusListCard" v-show="!noDataFlag">
                  <div
                    class="statusList"
                    v-for="(item, index) in noReadData"
                    :key="index"
                  >
                    <span class="redPoint"> </span>
                    <p class="top">
                      <span class="meterNumber"
                        >【{{ item.meterNumber }}号表计】</span
                      >
                      <span class="meterStatus">已离线!</span>
                      <span class="meterRadio"
                        ><a-radio-group
                          v-if="showFlag"
                          @change="radioChange(item.id)"
                          ><a-radio :value="false"></a-radio></a-radio-group
                      ></span>
                    </p>
                    <p class="bottom">
                      <span class="location">
                        安装位置：{{ item.location }}</span
                      >
                      <span class="time">{{ item.alarmDate }}</span>
                    </p>
                  </div>
                </div>
                <div class="noDataCard" v-show="noDataFlag">
                  <img
                    class="noData"
                    src="@assets/images/home/errorNoMsg.png"
                    alt=""
                  />
                  <br />
                  <span class="noDataTitle">暂无未读数据...</span>
                </div>
              </a-tab-pane>
              <a-tab-pane key="1" tab="已读">
                <div class="statusListCard" v-show="!yesDataFlag">
                  <div
                    class="statusList"
                    v-for="(item, index) in readData"
                    :key="index"
                  >
                    <p class="top">
                      <span class="meterNumber"
                        >【{{ item.meterNumber }}号表计】</span
                      >
                      <span class="meterStatus"> 已离线!</span>
                    </p>
                    <p class="bottom">
                      <span class="location">
                        安装位置：{{ item.location }}</span
                      >
                      <span class="time">{{ item.alarmDate }}</span>
                    </p>
                  </div>
                </div>
                <div class="noDataCard" v-show="yesDataFlag">
                  <img
                    class="noData"
                    src="@assets/images/home/errorNoMsg.png"
                    alt=""
                  />
                  <br />
                  <span class="noDataTitle">暂无已读数据...</span>
                </div>
              </a-tab-pane>
              <a slot="tabBarExtraContent" @click="allRead"> 全部已读 </a>
            </a-tabs>
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </a-layout>
</template>

<script>
import { mapGetters } from "vuex";
import SideMenu from "../components/SideMenu";
import {webSocektUrl} from "@/api/url.js";
var synth = window.speechSynthesis;
var msg = new SpeechSynthesisUtterance();
export default {
  name: "Home",
  data() {
    return {
      headerImageUrl: "",
      showFlag: true,
      radioFlag: false,
      yesDataFlag: false,
      noDataFlag: false,
      noReadData: [],
      readData: [],
      errorReadOrNoreadFlag: false,
      dataFlag: false,
      visible: false,
      warnTop: {},
      intervalId: null, // 定时器
      count: 0, // 告警未处理数
      fullWidth: document.documentElement.clientWidth,
      fullHeight: document.documentElement.clientHeight,
      collapsed: false,
      nodesize: 1,
      departlimit: 1,
      showScreen: false,
      openCurrent: "",
      // 播放时间
      lastRunTime: null,
      // s是否正在播放
      isPlaying: false,
      showsList: [],
      rbList: [8, 49, 48],
      showModel: [false, false, false],
      //以下系统通知部分
      total: {},
    };
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["User"]),
  },
  created() {
    this.getUserInfo();
  },
  mounted() {
    this.showWat();
    //监听屏幕宽度
    const that = this;
    window.onresize = () => {
      return (() => {
        window.fullWidth = document.documentElement.clientWidth;
        that.fullWidth = window.fullWidth;
      })();
    };
    // that.checkPath();
    that.getTodayTimeList();
    that.getUnitList();
    that.getTypeList();
    that.getDepartment();
    that.getDeviceMenu();
    that.getMenuManage();
    this.getSaveClassification();
    this.systemNotice();
    // that.dataRefreh();
    // 客户端初始化完成
    // if (!this.$mqtt.client) {
    //   this.MQTTconnect();
    // }
  },
  watch: {
    $route(to, from) {
      if (to.path == "/screen") {
        this.showScreen = true;
        this.collapsed = true;
      } else {
        this.showScreen = false;
        this.collapsed = false;
      }
      if (from.path == "/login") {
        // this.MQTTconnect();
      }
    },
    count(val, old) {
      if (parseInt(val) > parseInt(old)) {
        // 有新消息产生
        this.isPlaying = false;
        // this.play();
      }
    },
  },
  methods: {
    getUserInfo() {
      // 获得用户数据
      this.$http
        .get("/oauth/userinfo")
        .then((res) => {
          if (res.code == 1) {
            // this.user = res.data;
            // this.userAvatar = process.env.VUE_APP_URL + "/" + res.data.avatar;
            this.headerImageUrl =
              process.env.VUE_APP_URL + "/" + res.data.avatar;
              
            // this.saveImage = res.data.avatar;
          }
        })
        .catch(() => {});
    },
    //全部已读按钮
    allRead() {
      this.$http
        .put("/device/off/line/update/status/all")
        .then((res) => {
          if (res.code == 1) {
            this.noRead();
            this.yesRead();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    //radio未读框点击
    radioChange(val) {
      console.log("查看id", val);
      this.$http
        .put("/device/off/line/update/status/" + val)
        .then((res) => {
          if (res.code == 1) {
            this.noRead();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    //tab切换已读未读
    systemNoticeTabchange(val) {
      console.log("查看tab已读未读", val);
      // val == 0未读 1已读
      if (val == 0) {
        this.noRead();
      } else {
        this.yesRead();
      }
    },
    //系统通知——小铃铛
    systemNotice() {
      const yearWebScoket = `wss://${webSocektUrl}/websocket/device_off_line`; //请根据实际项目需要进行修改
      this.websock = new WebSocket(yearWebScoket); //新建一个webstock对象
      this.websock.onclose = this.websocketclose;
      this.websock.onmessage = this.websocketonmessageYear;
      this.websock.onopen = this.websocketonopenYear;
      this.websock.onerror = this.websocketonerror;
    },
    //年webSocket实时数据
    websocketonmessageYear(e) {
      //数据接收
      if (e.data == "已有相同窗口，请重新输入不同窗口号") {
        this.systemNotice();
      } else {
        this.total = JSON.parse(e.data);
        console.log("查看webSocket数据", this.total.total);
      }
    },
    websocketonopenYear() {
      // //websocket连接后发送数据(send发送)
      // let actions = { 你要发送给后台的参数 }; //请根据实际项目需要进行修改
      // this.websocketsend(JSON.stringify(actions));
    },
    websocketonerror() {
      //连接建立失败重连
      this.systemNotice();
    },
    // errorBtn() {
    //   this.visible = true;
    // },
    lookAll() {
      this.visible = false;
      this.errorReadOrNoreadFlag = true;
      this.noRead();
    },
    //系统通知——当日未读查询
    noRead() {
      this.showFlag = false;
      this.$http
        .get("/device/off/line/get/unread")
        .then((res) => {
          if (res.code == 1) {
            // this.$message.success(res.msg);
            this.showFlag = true;
            this.noReadData = res.data;

            if (this.noReadData.length != 0) {
              this.noDataFlag = false;
            } else {
              this.noDataFlag = true;
            }
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    //系统通知——当日已读查询
    yesRead() {
      this.$http
        .get("/device/off/line/get/read")
        .then((res) => {
          if (res.code == 1) {
            // this.$message.success(res.msg);
            this.readData = res.data;
            if (this.readData.length != 0) {
              this.yesDataFlag = false;
            } else {
              this.yesDataFlag = true;
            }
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    handleCancel() {
      this.errorReadOrNoreadFlag = false;
      this.errorReadOrNoreadFlag = false;
    },
    // 控制显示跳转的地方
    showWat() {
      var arr = [...this.$store.getters.routes];
      this.showsList = [];
      arr[0].children.forEach((ele) => {
        this.showsList.push(ele.id);
        if (ele.children.length != 0) {
          ele.children.forEach((chil) => {
            this.showsList.push(chil.id);
          });
        }
      });
      this.rbList.forEach((element, index) => {
        this.showsList.forEach((eles) => {
          if (element == eles) {
            this.$set(this.showModel, index, true);
          }
        });
      });
    },
    // 声音控制
    play() {
      this.lastRunTime = Date.now();
      let audio = document.querySelector("#audio");
      if (!this.isPlaying) {
        audio.play();
        this.isPlaying = true;
      }
      let timeOut = setTimeout(() => {
        this.stop(timeOut);
      }, 3600);
    },
    stop(timeOut) {
      this.currentTime = Date.now();
      let audio = document.querySelector("#audio");
      if (this.currentTime - this.lastRunTime < 3600) {
      } else {
        if (this.isPlaying) {
          audio.currentTime = 0;
          audio.pause();
          this.isPlaying = false;
        }
      }
      clearTimeout(timeOut);
    },
    MQTTconnect() {
      if (this.$store.state.User.Authorization) {
        // this.$mqtt.subscribe(
        //   "device/+/update/data",
        //   (topic, data) => {
        //     let message = JSON.parse(data);
        //     console.log(topic, message);
        //     if (message.current) {
        //       this.$store.dispatch("CheckAlarm", message);
        //     }
        //   },
        //   (error) => {
        //     console.log("Mqtt订阅错误：", error);
        //   }
        // );
        this.$mqtt.subscribe(
          "warn/count/73fqz5yu",
          (topic, data) => {
            let message = JSON.parse(data);
            // console.log("显示", topic, message);
            this.count = message.untreated;
          },
          (error) => {
            console.log("Mqtt订阅错误：", error);
          }
        );
      }
    },
    //获取服务器当天时间
    getTodayTimeList() {
      this.$store.dispatch("SaveTodayTime");
    },
    // 获取用水单元
    getUnitList() {
      this.$store.dispatch("SaveUnit");
    },
    // 获得设备类型、用水单元等参数，存入axios
    getTypeList() {
      this.$store.dispatch("SaveType");
    },
    // 得到设备菜单
    getDeviceMenu() {
      this.$store.dispatch("SaveDeviceMenu");
    },
    // 获取菜单管理下菜单项
    getMenuManage() {
      this.$store.dispatch("SaveMenuList");
    },
    getDepartment() {
      this.$store.dispatch("Savesepartment");
    },
    //水资源分类
    getSaveClassification() {
      this.$store.dispatch("SaveClassification");
    },
    // 定时刷新数据函数 get消息条数 5分钟刷新一次
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId != null) {
        return;
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        this.$http
          .get("/warn/count")
          .then((res) => {
            if (res.code == 1) {
              console.log(res, "条数");
              if (res.data == [] || res.data == "") {
                this.count = 0;
              } else {
                this.count = res.data.untreated;
              }
            }
          })
          .catch(() => {});
      }, 3000000);
    },
    // 停止定时器
    clear() {
      clearInterval(this.intervalId); //清除计时器
      this.intervalId = null; //设置为null
    },
    // 跳转
    handleClick(e) {
      this.visible = true;
      this.current = e.key;
      this.openCurrent = e.key;
      if (e.key == "/login") {
        this.logout();
      }
    },
    // 清空一次告警跳转路径
    clearPath() {
      this.openCurrent = "";
    },
    logout() {
      this.$http
        .post(this.$api.Logout)
        .then((response) => {
          if (response.code == 1) {
            this.$store.dispatch("Logout");
            this.$store.dispatch("ClearRoutes");
            this.$router.replace({ path: "/logout", name: "Login" });
          }
        })
        .catch(() => {});
    },
  },
  components: {
    SideMenu,
  },
  destroyed() {
    // 在页面销毁后，清除计时器
    this.clear();
    // this.$mqtt.disconnect();
  },
};
</script>
<style scoped lang="less">
/deep/ .ant-modal-wrap {
  .ant-modal {
    .ant-modal-content {
      background-color: #f7f9fa;
      border-radius: 8px 8px 8px 8px;
      height: 500px;
      position: relative;
      .ant-modal-body {
        padding: 0;
        .modalDiv {
          .left {
            background-color: #fff;
            padding: 24px 16px;
            height: 500px;
            border-radius: 8px 8px 8px 8px;
            .imgDiv {
              background: #f7f9fa;
              border-radius: 8px 8px 8px 8px;
              padding: 10px 0 10px 16px;
              .leftImg {
                display: inline-block;
                img {
                  vertical-align: none !important;
                  display: table-cell;
                }
              }
              .rightDetail {
                display: inline-block;
                margin-left: 10px;
                .title {
                  font-size: 16px;
                  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                  font-weight: bold;
                  color: #333333;
                  margin-bottom: 7px;
                }
                .mark {
                  font-size: 12px;
                  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                  font-weight: 400;
                  color: #8d949e;
                }
              }
            }
            .title {
              font-size: 20px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #333333;
              margin-bottom: 24px;
            }
            .leftImg {
              display: inline-block;
              margin-top: 10px;
            }
            .rightDetail {
              display: inline-block;
            }
          }
          .right {
            padding: 24px;
            .statusListCard {
              overflow-y: scroll;
              height: 392px;
              padding-right: 20px;
              .statusList {
                height: 86px;
                background: #ffffff;
                border-radius: 8px 8px 8px 8px;
                padding: 16px;
                position: relative;
                margin-bottom: 16px;
                .redPoint {
                  width: 5px;
                  height: 5px;
                  position: absolute;
                  background-color: red;
                  display: block;
                  border-radius: 10px;
                  right: 2%;
                  top: 10px;
                }
                .top {
                  margin-bottom: 10px;
                  .meterNumber {
                    font-size: 16px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                  }
                  .meterStatus {
                    font-size: 16px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #ff5f5a;
                  }
                  .meterRadio {
                    float: right;
                  }
                }
                .bottom {
                  margin-left: 8px;
                  .location {
                    font-size: 14px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #8d949e;
                  }
                  .time {
                    float: right;
                    font-size: 12px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #8d949e;
                  }
                }
              }
            }
            .noDataCard {
              text-align: center;
              .noData {
                width: 50%;
                text-align: center;
                margin-top: 24px;
              }
              .noDataTitle {
                text-align: center;
                color: #8d949e;
              }
            }

            .statusListCard::-webkit-scrollbar {
              width: 8px;
            }
            .statusListCard::-webkit-scrollbar-thumb {
              border-radius: 10px;
              -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
              background: rgba(226, 228, 230, 0.5);
            }
            .statusListCard::-webkit-scrollbar-track {
              border-radius: 10px;
              background: rgba(241, 243, 245, 0.8);
            }
          }
        }
      }
    }
  }
}
.notice {
  width: 332px;
  height: 302px;
  padding: 8px;
  border-radius: 100px;
  /deep/ .noticeP {
    position: relative;
    .allRead {
      position: absolute;
      right: 0;
      .allReadA {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #8d949e;
      }
    }
    .title {
      font-size: 18px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
  }
  .divider {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .listCard {
    height: 170px;
    .noData {
      width: 100%;
      text-align: center;
      margin-top: 24px;
      .noDataTitle {
        text-align: center;
      }
    }
    .dataCard {
      overflow-y: auto;
      height: 160px;
      .ulClass {
        padding-left: 0;
        .liClass {
          list-style: none;
          margin-bottom: 24px;
          cursor: pointer;
          .name {
            width: 100px;
          }
          .status {
            width: 70px;
            color: #ff5f5a;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
          }
          .time {
            font-size: 12px;
            width: 105px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #8d949e;
          }
          .redPoint {
            width: 2px;
            height: 2px;
            background-color: red;
            display: block;
            float: right;
          }
        }
      }
    }
  }
}

.app-sider {
  .logo {
    border-bottom: 2px solid rgba(237, 238, 239, 0.8);
    box-shadow: 0 1px 4px rgb(0 21 41 / 8%);

    .h1Class {
      font-size: 18px;
      margin: 0 0 0 7px;
      color: #2d88ff;
    }
  }
}
/deep/ .ant-layout {
  overflow: hidden;
  min-width: 768px !important;
}
/deep/ .container-tree {
  flex: 1;
  height: 100% !important;
}
/deep/ .ant-layout-sider {
  background: #ffffff;
}
</style>
